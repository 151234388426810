import $ from 'jquery';
// import Vue from 'vue';
window.$=$;

// var VueComponents = {};
// var vueComponentList = [
//   require.context(".", false, /.+\.vue$/),
// ];
// 
// for(var vueComponentListItem of vueComponentList) {
//   for(var key of vueComponentListItem.keys()) {
//     let actualKey=key.replace(/\.vue|.*\//g, "");
//     let actualComponent=vueComponentListItem(key).default;
//     VueComponents[actualKey]=actualComponent;
//     Vue.component(actualKey, actualComponent);
//   }
// }
// 
// 
// $(function($) {
//   window.vueMixins=window.vueMixins || {}
//   $("[vue-dyn]").each(function() {
//     var $this=$(this);
//     var mixins=$this.attr("vue-dyn").split(",").filter(function(a) {
//       return a;
//     }).map(function(a) {
//       return window.vueMixins[a];
//     });
//     $this.removeAttr("vue-dyn");
// 
//     (new Vue({
//       el: this,
//       mixins: mixins,
//     }));
//   });
// });
